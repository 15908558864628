import * as React from 'react'
import { Link, useNavigation } from '@remix-run/react'
import {
	Menu,
	MenuButton,
	MenuItems,
	MenuLink,
	MenuPopover,
	useMenuButtonContext,
} from '@reach/menu-button'
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion'
import { useSpinDelay } from 'spin-delay'

import { cn } from '~/utils/index.ts'
import { Logo } from '~/components/Logo.tsx'
import { Spinner } from '~/components/Spinner.tsx'

export const links = [
	{ label: 'Główna', to: '/' },
	{ label: 'O mnie', to: '/#about' },
	{ label: 'Portfolio', to: '/#portfolio' },
	{ label: 'Kontakt', to: '/#contact' },
]

export function NavLink({
	label,
	to,
	color = 'dark',
	className,
	...props
}: (typeof links)[0] & {
	color?: 'light' | 'dark'
} & React.ComponentPropsWithoutRef<'li'>) {
	return (
		<li
			className={cn(
				'font-serif text-heading3 font-medium text-black hover:text-key focus:text-key',
				{
					'text-black': color === 'dark',
					'text-white': color === 'light',
				},
				className,
			)}
			{...props}
		>
			<Link to={to}>{label}</Link>
		</li>
	)
}

export function LogoLink({ className }: { className?: string }) {
	return (
		<Link
			to="/"
			aria-label="główna"
			className={cn('fill-key text-key', className)}
		>
			<Logo height="64" width="124" />
		</Link>
	)
}

function MobileMenu() {
	const { isExpanded } = useMenuButtonContext()
	const reducedMotion = useReducedMotion()

	React.useEffect(() => {
		if (isExpanded) {
			document.body.classList.add('overflow-hidden')
		} else {
			document.body.classList.remove('overflow-hidden')
		}
	}, [isExpanded])

	return (
		<AnimatePresence>
			{isExpanded && (
				<MenuPopover
					position={r => ({
						top: `calc(${Number(r?.top) + Number(r?.height)}px + 16px)`,
						left: 0,
						bottom: 0,
						right: 0,
					})}
					style={{ display: 'block' }}
					className="z-50"
				>
					<motion.div
						initial={{ y: -50, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: -50, opacity: 0 }}
						transition={{
							duration: reducedMotion ? 0 : 0.15,
						}}
						className="flex h-full flex-col overflow-y-scroll bg-white text-xl"
					>
						<MenuItems className="border-none bg-transparent p-0">
							{links.map(link => (
								<MenuLink
									className="border-b border-black py-9 text-center font-serif text-heading3 font-medium text-black hover:bg-transparent hover:text-key focus:bg-transparent focus:text-key"
									as={Link}
									key={link.to}
									to={link.to}
								>
									{link.label}
								</MenuLink>
							))}
						</MenuItems>
					</motion.div>
				</MenuPopover>
			)}
		</AnimatePresence>
	)
}

export function Header() {
	const navigation = useNavigation()
	const showSpinner = useSpinDelay(navigation.state !== 'idle', {
		delay: 200,
		minDuration: 300,
	})

	return (
		<header className="mx-auto h-24 w-full max-w-screen-xl">
			<nav className="h-full w-full">
				<ul className="hidden h-full w-full items-center justify-center gap-12 md:flex">
					<li className="relative order-2">
						<LogoLink className={cn({ 'opacity-0': showSpinner })} />
						{showSpinner && (
							<Spinner className="absolute left-1/2 top-1/2 h-7 w-7 -translate-x-1/2 -translate-y-1/2" />
						)}
					</li>
					{links.map((link, index) => (
						// eslint-disable-next-line jsx-a11y/anchor-has-content
						<NavLink
							key={link.to}
							{...link}
							className={
								(links.length - 1) / 2 >= index ? 'order-1' : 'order-3'
							}
						/>
					))}
				</ul>
				<div className="flex h-full w-full items-center justify-between px-8 md:hidden">
					<div className="relative">
						<LogoLink className={cn({ 'opacity-0': showSpinner })} />
						{showSpinner && (
							<Spinner className="absolute left-1/2 top-1/2 h-7 w-7 -translate-x-1/2 -translate-y-1/2" />
						)}
					</div>
					<div>
						<Menu>
							{({ isExpanded }) => {
								const state = isExpanded ? 'open' : 'closed'
								return (
									<>
										<MenuButton
											className="relative inline-flex h-14 w-14 items-center justify-center rounded-full p-1"
											aria-label="toggle mobile menu button"
										>
											<svg
												width="32"
												height="32"
												viewBox="0 0 32 32"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<motion.rect
													animate={state}
													variants={{
														open: { rotate: 45, y: 7 },
														closed: { rotate: 0, y: 0 },
													}}
													x="6"
													y="9"
													width="20"
													height="2"
													rx="1"
													fill="currentColor"
												/>
												<motion.rect
													animate={state}
													variants={{
														open: { opacity: 0 },
														closed: { opacity: 1 },
													}}
													x="6"
													y="15"
													width="20"
													height="2"
													rx="1"
													fill="currentColor"
												/>
												<motion.rect
													animate={state}
													variants={{
														open: { rotate: -45, y: -5 },
														closed: { rotate: 0, y: 0 },
													}}
													x="6"
													y="21"
													width="20"
													height="2"
													rx="1"
													fill="currentColor"
												/>
											</svg>
										</MenuButton>

										<MobileMenu />
									</>
								)
							}}
						</Menu>
					</div>
				</div>
			</nav>
		</header>
	)
}
