import * as React from 'react'

import { cn } from '~/utils/index.ts'

import { Typography, type Props as TypographyProps } from './Typography.tsx'

export function ErrorMessage({ className, ...props }: TypographyProps<'p'>) {
	return (
		<Typography
			{...props}
			className={cn('text-red-500', className)}
			role="alert"
		/>
	)
}
