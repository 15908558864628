import { links, NavLink } from '~/components/Header.tsx'
import {
	FacebookIcon,
	PlanujemyweseleIcon,
	YoutubeIcon,
} from '~/components/Icons.tsx'
import { Logo } from '~/components/Logo.tsx'
import { Typography } from '~/components/Typography.tsx'

export function Footer() {
	return (
		<footer className="bg-secondaryDarker py-16 shadow-top">
			<div className="mx-auto flex max-w-screen-xl flex-col items-center justify-around gap-8 md:flex-row">
				<div className="flex flex-col gap-4">
					<Logo className="h-auto w-56 fill-white" />
					<div className="flex flex-wrap gap-4">
						<a
							href="https://www.facebook.com/fotovideo24/"
							className="flex aspect-square w-12 items-center justify-center rounded-full bg-key text-white shadow-lg shadow-black hover:bg-key/75 focus:bg-key/75"
						>
							<span className="sr-only">Facebook</span>
							<FacebookIcon />
						</a>
						<a
							href="https://www.youtube.com/@videofotokrzysztof9478"
							className="flex aspect-square w-12 items-center justify-center rounded-full bg-key text-white shadow-lg shadow-black hover:bg-key/75 focus:bg-key/75"
						>
							<span className="sr-only">YouTube</span>
							<YoutubeIcon />
						</a>
						<a
							href="https://www.planujemywesele.pl/24635-film-fotografia-krzysztof-dabrowski"
							className="flex aspect-square w-12 items-center justify-center rounded-full bg-key text-white shadow-lg shadow-black hover:bg-key/75 focus:bg-key/75"
						>
							<span className="sr-only">PlanujemyWesele</span>
							<PlanujemyweseleIcon />
						</a>
					</div>
				</div>
				<nav className="flex flex-col gap-4">
					<Typography
						as="h2"
						color="light"
						className="border-b-2 border-current"
					>
						Menu
					</Typography>
					<ul className="flex flex-col gap-2">
						{links.map(link => (
							// eslint-disable-next-line jsx-a11y/anchor-has-content
							<NavLink color="light" key={link.to} {...link} />
						))}
					</ul>
				</nav>
			</div>
		</footer>
	)
}
