import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type DataFunctionArgs,
  type LinksFunction,
  type MetaFunction } from
'@remix-run/node';
import {
  isRouteErrorResponse,
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError } from
'@remix-run/react';
import reachMenuButtonStylesheetUrl from '@reach/menu-button/styles.css';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import yetAnotherImageLightboxStylesheetUrl from 'yet-another-react-lightbox/styles.css';

import {
  getDomainUrl,
  getMetas,
  getUrl,
  removeTrailingSlash } from
'~/utils/index.ts';
import { useNonce } from '~/utils/nonce-provider.ts';
import { ErrorMessage } from '~/components/Error.tsx';
import { Footer } from '~/components/Footer.tsx';
import { Header } from '~/components/Header.tsx';
import { Hero } from '~/components/sections/Hero.tsx';
import { getEnv } from '~/env.server.ts';

import noScriptStylesheetUrl from './styles/no-script.css';
import tailwindStylesheetUrl from './styles/tailwind.css';
import { csrf } from './utils/csrf.server.ts';
import { honeypot } from './utils/honeypot.server.ts';

export const links: LinksFunction = () => {
  return [
  {
    rel: 'preload',
    href: 'https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,400;0,500;1,400;1,500&family=Open+Sans:wght@300;400&display=swap',
    as: 'style'
  },
  { rel: 'preload', href: reachMenuButtonStylesheetUrl, as: 'style' },
  {
    rel: 'preload',
    href: (yetAnotherImageLightboxStylesheetUrl as string),
    as: 'style'
  },
  { rel: 'preload', href: tailwindStylesheetUrl, as: 'style' },
  ...(cssBundleHref ?
  [{ rel: 'preload', href: cssBundleHref, as: 'style' }] :
  []),
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '192X192',
    href: '/images/android-chrome-192.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '512x512',
    href: '/images/android-chrome-512.png'
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/images/apple-touch-icon.png'
  },
  { rel: 'icon', href: '/favicon.ico' },
  { rel: 'manifest', href: '/manifest.webmanifest' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,400;1,500&family=Open+Sans:wght@300;400&display=swap'
  },
  { rel: 'stylesheet', href: reachMenuButtonStylesheetUrl },
  { rel: 'stylesheet', href: (yetAnotherImageLightboxStylesheetUrl as string) },
  { rel: 'stylesheet', href: tailwindStylesheetUrl },
  ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : [])];

};
export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const requestInfo = data?.requestInfo;

  return getMetas({
    origin: requestInfo?.origin ?? '',
    url: getUrl(requestInfo)
  });
};

export type LoaderData = typeof loader;

export async function loader({ request }: DataFunctionArgs) {
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();
  const honeyProps = honeypot.getInputProps();
  return json(
    {
      ENV: getEnv(),
      requestInfo: {
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname
      },
      honeyProps,
      csrfToken
    },
    {
      headers: csrfCookieHeader ?
      { 'set-cookie': csrfCookieHeader } :
      undefined
    }
  );
}

function CanonicalLink({ origin }: {origin: string;}) {
  const { pathname } = useLocation();
  const canonicalUrl = removeTrailingSlash(`${origin}${pathname}`);

  return <link rel="canonical" href={canonicalUrl} />;
}

export default function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();

  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				<html lang="pl" className="h-full scroll-smooth">
					<head>
						<CanonicalLink origin={data.requestInfo.origin} />
						<Meta />
						<Links />
						<noscript>
							<link rel="stylesheet" href={noScriptStylesheetUrl} />
						</noscript>
					</head>
					<body className="h-full min-h-screen bg-primary font-sans text-black">
						<Header />
						<main className="min-h-screen">
							<Outlet />
						</main>
						<Footer />
						<script
              nonce={nonce}
              dangerouslySetInnerHTML={{
                __html: `window.ENV = ${JSON.stringify(data.ENV)}`
              }} />

						<ScrollRestoration nonce={nonce} />
						<Scripts nonce={nonce} />
						<LiveReload nonce={nonce} />
					</body>
				</html>
			</HoneypotProvider>
		</AuthenticityTokenProvider>);

}

const ErrorImages = {
  webp: '/images/hero.webp',
  png: '/images/hero.png'
};

export function ErrorBoundary() {
  const error = useRouteError();
  const nonce = useNonce();

  if (isRouteErrorResponse(error)) {
    return (
      <html lang="pl" className="h-full scroll-smooth">
				<head>
					<title>O nie...</title>
					<Links />
				</head>
				<body className="h-full min-h-screen w-screen overflow-x-hidden bg-primary font-sans text-black">
					<Hero
            content={
            <div className="flex h-full flex-col items-start justify-center gap-8">
								<ErrorMessage className="text-3xl font-bold">
									{error.status} - {error.data.message}
								</ErrorMessage>
								<Link to="/" className="text-blue-600 underline">
									Wróć na stronę główną
								</Link>
							</div>}

            img={ErrorImages} />

					<Scripts nonce={nonce} />
				</body>
			</html>);

  }

  let errorMessage = 'Unknown error';
  if (error instanceof Error) {
    errorMessage = error.message;
  }

  return (
    <html lang="pl" className="h-full scroll-smooth">
			<head>
				<title>O nie...</title>
				<Links />
			</head>
			<body className="h-full min-h-screen w-screen overflow-x-hidden bg-primary font-sans text-black">
				<Hero
          content={
          <div className="flex h-full flex-col items-start justify-center gap-8">
							<ErrorMessage className="text-3xl font-bold">
								O nie, coś poszło nie tak.
							</ErrorMessage>
							<ErrorMessage className="text-2xl font-bold">
								{errorMessage}
							</ErrorMessage>
							<Link to="/" className="text-blue-600 underline">
								Wróć na stronę główną
							</Link>
						</div>}

          img={ErrorImages} />


				<Scripts nonce={nonce} />
			</body>
		</html>);

}