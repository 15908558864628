export function FacebookIcon(props: React.SVGAttributes<SVGElement>) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M8.57937 4.63149C8.57937 4.48188 8.60015 4.15878 8.6417 3.66426C8.68638 3.16869 8.87338 2.65027 9.20895 2.10588C9.5414 1.56357 10.0806 1.07632 10.8307 0.645167C11.5777 0.216095 12.6571 0 14.0659 0H18.0345V4.32501H15.1287C14.8409 4.32501 14.5729 4.43306 14.3308 4.64603C14.0908 4.85901 13.9672 5.0616 13.9672 5.24964V7.94044H18.0335C17.9909 8.46717 17.9442 8.97935 17.8901 9.47388L17.7291 10.7808C17.6647 11.2224 17.5992 11.6317 17.5348 12.0088H13.9381V24H8.57937V12.0078H5.96545V7.94044H8.57937V4.63149Z"
				fill="white"
			/>
		</svg>
	)
}

export function PlanujemyweseleIcon(props: React.SVGAttributes<SVGElement>) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_505_2)">
				<path
					d="M22.0431 4.34458L20.91 5.47771L18.6572 7.7305L9.74047 16.6472L7.40675 14.3L5.93636 12.8296L7.52815 11.2378L9.87537 13.585L17.6994 5.761L17.5375 5.59912C16.6877 4.76276 15.595 4.30411 14.4349 4.30411C13.2613 4.30411 12.1821 4.76276 11.3457 5.59912L11.2918 5.63959L9.65953 7.31232L8.02727 5.63959L7.9868 5.59912C7.15044 4.76276 6.05777 4.30411 4.89765 4.30411C3.72405 4.34458 2.59091 4.80323 1.79501 5.59912C0.958651 6.43549 0.5 7.52815 0.5 8.68827C0.5 9.78094 0.877713 10.7927 1.59267 11.5751L1.67361 11.656L1.71408 11.6965L1.79501 11.8179L2.05132 12.0742L5.85543 15.8783L9.65953 19.6959L13.4636 15.8918L17.6455 11.71L23.5 5.81496L22.0431 4.34458Z"
					fill="white"
					stroke="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_505_2">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}

export function YoutubeIcon(props: React.SVGAttributes<SVGElement>) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M18.7232 3.8623H5.27681C2.63865 3.8623 0.5 6.00095 0.5 8.63911V15.3609C0.5 17.999 2.63865 20.1377 5.27681 20.1377H18.7232C21.3614 20.1377 23.5 17.999 23.5 15.3609V8.63911C23.5 6.00095 21.3614 3.8623 18.7232 3.8623ZM15.4927 12.327L9.20336 15.3267C9.03578 15.4066 8.8422 15.2844 8.8422 15.0988V8.91202C8.8422 8.72373 9.04086 8.60169 9.2088 8.68681L15.4981 11.8739C15.6851 11.9687 15.6819 12.2368 15.4927 12.327Z"
				fill="white"
			/>
		</svg>
	)
}
